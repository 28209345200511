import styled from "@emotion/styled"
import React from "react"

import colors from "../../../styles/colors"
import {
  MobileMediaQuery,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_HORIZONTAL,
} from "../../../styles/constants"
import { ContentPadding, NewCard } from "../../cardWrappers/newCard"
import Typography from "../../typography"
import MostCommonIssuesResolutionRate from "../../illustrations/mostCommonIssuesResolutionRate"

const SectionTitle = styled(Typography)`
  color: ${colors.primary};
`

const Card = styled(NewCard)`
  ${MobileMediaQuery} {
    flex-direction: column-reverse;
  }
`

const CardTitle = styled(Card)`
  ${MobileMediaQuery} {
    margin-top: 3rem;
  }
`

const TextContent = styled.div`
  padding: 0 ${PADDING_HORIZONTAL}px;
  flex: 1;

  ${MobileMediaQuery} {
    padding: 0 ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

const LargeTitle = styled(ContentPadding)`
  color: ${colors.primary};
  margin-bottom: 2rem;
`

const UniqueCard = styled(Card)`
  ${MobileMediaQuery} {
    flex-direction: column;
  }
`

const Image = styled.div`
  flex: 2;
  padding-right: ${PADDING_HORIZONTAL}px;
  ${MobileMediaQuery} {
    justify-content: center;
    padding: 0 4px;
    margin-bottom: 4rem;
  }
`

const UniqueProductInsights = () => (
  <>
    <CardTitle>
      <LargeTitle style={{ maxWidth: "1100px" }}>
        <Typography variant="hero" as="h2">
          Unique product insights
        </Typography>
      </LargeTitle>
    </CardTitle>
    <UniqueCard>
      <Image>
        <MostCommonIssuesResolutionRate />
      </Image>
      <TextContent>
        <SectionTitle variant="bodyLarge" as="h3">
          Track your performance
        </SectionTitle>
        <Typography variant="bodyLarge">
          Mavenoid gives you access to detailed analytics to help you improve
          both resolution rate and customer satisfaction.
        </Typography>
        <SectionTitle variant="bodyLarge" as="h3" style={{ marginTop: "2rem" }}>
          Support your R&D
        </SectionTitle>
        <Typography variant="bodyLarge">
          With more data from more customer interactions, you’ll be able to
          uncover root causes and act quicker on production errors or failed
          updates. Pinpoint where customers get stuck to identify gaps in your
          documentation.
        </Typography>
      </TextContent>
    </UniqueCard>
  </>
)

export default UniqueProductInsights
