import React from "react"

export const WhyMavenoidHeaderHorizontalSvg = props => (
  <svg fill="none" viewBox="0 0 375 93" {...props}>
    <path
      d="M146.802 85.2688c15.655 0 28.346-12.0656 28.346-26.9493 0-14.8837-12.691-26.9494-28.346-26.9494s-28.346 12.0657-28.346 26.9494c0 14.8837 12.691 26.9493 28.346 26.9493zM234.437 85.2399c5.837 0 10.568-4.4986 10.568-10.0479 0-5.5493-4.731-10.048-10.568-10.048s-10.569 4.4987-10.569 10.048 4.732 10.0479 10.569 10.0479z"
      fill="#F96443"
    />
    <path
      d="M234.438 79.4026c2.446 0 4.429-1.8852 4.429-4.2108 0-2.3256-1.983-4.2108-4.429-4.2108s-4.429 1.8852-4.429 4.2108c0 2.3256 1.983 4.2108 4.429 4.2108zM146.802 72.52c8.249 0 14.936-6.3578 14.936-14.2007 0-7.8428-6.687-14.2006-14.936-14.2006-8.249 0-14.937 6.3578-14.937 14.2006 0 7.8429 6.688 14.2007 14.937 14.2007z"
      fill="#F4DDD7"
    />
    <path
      d="M116.96 39.356l33.691 5.9823c1.253.2032 2.414.8421 3.269 1.7424l19.029 20.212c.733.755 1.741 1.1907 2.841 1.1907h36.471c1.069 0 2.107-.4357 2.841-1.2197l3.329-3.5429c1.191-1.2488 2.871-1.9748 4.673-1.9748l37.052.0871c0-.029-.031-.058-.031-.0871-3.971-7.7828-9.255-12.9229-16.953-16.0012-25.871-9.0605-57.21-14.6363-65.488-15.6236-.886-.1162-1.711-.5518-2.26-1.1907l-1.772-2.0909c-.55-.6679-1.375-1.1035-2.26-1.1906l-40.503-4.9659c-1.283-.1452-2.566.3775-3.299 1.3939l-10.691 14.7234c-.55.7551-.733 1.6844-.519 2.5846.183-.0581.366-.0871.58-.029z"
      fill="#F96443"
    />
    <path
      d="M220.233 65.2889l-3.329 3.543c-1.191 1.2487-2.871 1.9747-4.673 1.9747H175.79c-1.771 0-3.482-.726-4.642-1.9747l-19.03-20.2121c-.489-.5227-1.161-.8712-1.894-1.0164l-33.233-5.8951 1.1 4.0075c.336 1.1907 1.344 2.1199 2.596 2.3813l27.155 4.5303c.672.1452 1.283.4646 1.741.9293l20.007 21.4317c.641.6389 1.558 1.0164 2.504 1.0164h85.557c1.925 0 3.452-1.4811 3.452-3.2816V66.073c0-.6389-.061-1.2487-.184-1.8585l-37.845-.0872c-1.099-.0581-2.107.3776-2.841 1.1616z"
      fill="#F96443"
    />
    <path
      d="M223.074 61.746c-1.772 0-3.482.726-4.673 1.9748l-3.33 3.5429c-.733.755-1.741 1.2197-2.84 1.2197H175.79c-1.069 0-2.107-.4356-2.84-1.1907l-19.03-20.212c-.855-.9002-2.016-1.5391-3.268-1.7424l-33.691-5.9823c-.214-.0291-.398-.0291-.611.029 0 .0291.03.0872.03.1162l.611 2.2071 33.233 5.9242c.733.1161 1.405.4646 1.894 1.0164l19.029 20.212c1.192 1.2487 2.872 1.9747 4.643 1.9747h36.471c1.772 0 3.482-.726 4.673-1.9747l3.33-3.5429c.733-.7551 1.741-1.2197 2.841-1.2197l37.845.0871c-.153-.8131-.428-1.5972-.794-2.3232l-37.082-.1162z"
      fill="#F4DDD7"
    />
    <rect
      x="304"
      y="13.1296"
      width="116.395"
      height="74.1662"
      rx="4.58455"
      fill="#F96443"
    />
    <path d="M335.493 13.0884h-17.207V5.02235h17.207v8.06605z" fill="#F4DDD7" />
    <path
      d="M348.344 0h29.147l7.473 13.4434H340.87L348.344 0z"
      fill="#F96443"
    />
    <circle
      cx="361.843"
      cy="50.2033"
      r="27.1332"
      fill="#F96443"
      stroke="#F4DDD7"
      strokeWidth="5.95992"
    />
    <circle cx="361.841" cy="50.203" r="20.434" fill="#F4DDD7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-.566177 46.073c.5480076.4994.5874611 1.3484.088127 1.8964L-15.343 64.2833c-.5607.6421-.8668 1.4521-.8668 2.3004v24.7268c0 .7414-.601 1.3424-1.3423 1.3424-.7414 0-1.3424-.601-1.3424-1.3424V66.5837c0-1.5107.5504-2.9525 1.5422-4.0811l.0079-.009 14.88184-16.3324c.49934-.548 1.34838-.5875 1.896383-.0882zM20.2029 46.073c.548-.4993 1.3971-.4598 1.8964.0882l14.8818 16.3324.008.009c.9917 1.1286 1.5422 2.5704 1.5422 4.0811v24.7268c0 .7414-.601 1.3424-1.3424 1.3424-.7414 0-1.3424-.601-1.3424-1.3424V66.5837c0-.8483-.306-1.6584-.8668-2.3004L20.1148 47.9694c-.4993-.548-.4599-1.397.0881-1.8964z"
      fill="#F96443"
    />
    <path
      d="M9.66947 31.118c2.75793 0 4.99373-2.2358 4.99373-4.9937s-2.2358-4.9937-4.99373-4.9937c-2.75794 0-4.99369 2.2358-4.99369 4.9937s2.23575 4.9937 4.99369 4.9937zM83.314 19.8421s.0269-.0268.0269.2417c0 .2684-.3222.4295-.6981.4832l-11.6519 2.121c-.1074 0-.188 0-.2954-.0268l-7.759-2.2016v-1.3424c0 .0269 11.2761-.9128 20.3775.7249zM39.5249 19.8421s-.0269-.0268-.0269.2417c0 .2684.3222.4295.6981.4832l11.6519 2.121c.1074 0 .188 0 .2954-.0268l7.759-2.2016v-1.3424c-.0269.0269-11.2761-.9128-20.3775.7249z"
      fill="#F7DCD6"
    />
    <path
      d="M59.3931 21.5872h4.0271v-2.7921c0-.6712-.5369-1.235-1.235-1.235h-1.584c-.6712 0-1.235.5369-1.235 1.235v2.7921h.0269zM58.1323 28.3262h6.5509v-5.5038c0-.6712-.537-1.235-1.235-1.235h-4.1077c-.6712 0-1.235.537-1.235 1.235v5.5038h.0268zM16.3829 50.1533H2.95898v3.8661H16.3829v-3.8661z"
      fill="#F96443"
    />
    <path
      d="M12.0602 54.0188H7.28125v3.8661h4.77895v-3.8661zM58.8193 43.1348h5.1952c1.6866 0 3.0539-1.3673 3.0539-3.0539v-8.7122c0-1.6867-1.3673-3.0539-3.0539-3.0539h-5.1952c-1.6866 0-3.0539 1.3672-3.0539 3.0539v8.7122c0 1.6866 1.3673 3.0539 3.0539 3.0539z"
      fill="#F96443"
    />
    <path
      d="M28.7051 44.1122l27.3311-5.1011v-7.8396l-27.3311-1.1544v14.0951zM16.9198 78.4517H2.44884c-1.691409 0-3.033801-1.3693-3.033801-3.0338V60.92c0-1.6914 1.36924-3.0338 3.033801-3.0338H16.9198c1.6914 0 3.0338 1.3693 3.0338 3.0338v14.4979c0 1.6645-1.3692 3.0338-3.0338 3.0338z"
      fill="#F96443"
    />
    <path
      d="M9.67189 71.7928c1.92761 0 3.49021-1.5627 3.49021-3.4903s-1.5626-3.4902-3.49021-3.4902c-1.92761 0-3.49025 1.5626-3.49025 3.4902 0 1.9276 1.56264 3.4903 3.49025 3.4903z"
      fill="#F7DCD6"
    />
    <path
      d="M9.67169 75.1363c3.77421 0 6.83381-3.0596 6.83381-6.8338s-3.0596-6.8337-6.83381-6.8337c-3.7742 0-6.8338 3.0595-6.8338 6.8337 0 3.7742 3.0596 6.8338 6.8338 6.8338z"
      stroke="#F7DCD6"
      strokeWidth="2.07665"
      strokeMiterlimit="10"
    />
    <path
      d="M25.6521 26.0972H-6.31036c-1.68662 0-3.0539 1.3672-3.0539 3.0539v17.9478c0 1.6866 1.36728 3.0539 3.0539 3.0539H25.6521c1.6866 0 3.0539-1.3673 3.0539-3.0539V29.1511c0-1.6867-1.3673-3.0539-3.0539-3.0539z"
      fill="#F96443"
    />
  </svg>
)
