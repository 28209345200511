import React from "react"

export const WhyMavenoidHeaderSvg = props => (
  <svg fill="none" viewBox="0 0 317 600" {...props}>
    <path
      d="M73.1032 349.663c33.3558 0 60.3958-25.708 60.3958-57.421s-27.04-57.421-60.3958-57.421c-33.3559 0-60.3962 25.708-60.3962 57.421s27.0403 57.421 60.3962 57.421zM259.823 349.601c12.437 0 22.518-9.585 22.518-21.409 0-11.824-10.081-21.409-22.518-21.409-12.437 0-22.518 9.585-22.518 21.409 0 11.824 10.081 21.409 22.518 21.409z"
      fill="#F96443"
    />
    <path
      d="M259.828 337.164c5.211 0 9.436-4.017 9.436-8.972 0-4.955-4.225-8.972-9.436-8.972-5.212 0-9.437 4.017-9.437 8.972 0 4.955 4.225 8.972 9.437 8.972zM73.1025 322.499c17.5765 0 31.8255-13.546 31.8255-30.257 0-16.711-14.249-30.257-31.8255-30.257-17.5766 0-31.8252 13.546-31.8252 30.257 0 16.711 14.2486 30.257 31.8252 30.257z"
      fill="#F4DDD7"
    />
    <path
      d="M9.51949 251.837L81.305 264.584c2.6684.433 5.1415 1.794 6.9638 3.712l40.5462 43.066c1.562 1.608 3.71 2.537 6.053 2.537h77.708c2.278 0 4.49-.929 6.052-2.599l7.094-7.549c2.538-2.661 6.118-4.208 9.958-4.208l78.944.186c0-.062-.065-.124-.065-.186-8.46-16.582-19.72-27.534-36.12-34.093-55.125-19.305-121.899-31.186-139.536-33.289-1.888-.248-3.645-1.176-4.816-2.537l-3.775-4.455c-1.172-1.424-2.929-2.352-4.816-2.537l-86.2991-10.581c-2.7334-.309-5.4669.804-7.0288 2.97L9.38932 246.392c-1.17147 1.609-1.56197 3.589-1.10639 5.507.39049-.124.78098-.186 1.23656-.062z"
      fill="#F96443"
    />
    <path
      d="M229.563 307.093l-7.094 7.548c-2.538 2.661-6.117 4.208-9.957 4.208h-77.643c-3.775 0-7.419-1.547-9.893-4.208l-40.5457-43.065c-1.0413-1.114-2.4731-1.856-4.0351-2.166L9.58594 256.849l2.34296 8.539c.7159 2.537 2.8636 4.517 5.532 5.074l57.8579 9.653c1.4319.309 2.7335.99 3.7097 1.98l42.6285 45.664c1.367 1.361 3.319 2.166 5.337 2.166h182.295c4.1 0 7.354-3.156 7.354-6.992v-14.17c0-1.361-.13-2.66-.39-3.96l-80.637-.185c-2.343-.124-4.491.804-6.053 2.475z"
      fill="#F96443"
    />
    <path
      d="M235.616 299.544c-3.775 0-7.42 1.546-9.958 4.207l-7.094 7.549c-1.562 1.609-3.71 2.599-6.052 2.599h-77.643c-2.278 0-4.491-.928-6.053-2.537l-40.5462-43.066c-1.8223-1.918-4.2955-3.279-6.9638-3.712L9.52039 251.837c-.45557-.062-.84607-.062-1.30164.062 0 .062.06508.186.06508.248l1.30164 4.702 70.80933 12.623c1.562.247 2.9938.99 4.0351 2.166l40.5461 43.065c2.538 2.661 6.118 4.208 9.893 4.208h77.708c3.774 0 7.419-1.547 9.957-4.208l7.094-7.549c1.562-1.608 3.71-2.598 6.053-2.598l80.637.185c-.326-1.732-.912-3.403-1.693-4.95l-79.009-.247z"
      fill="#F4DDD7"
    />
    <rect
      x="34"
      y="497.975"
      width="248.001"
      height="158.025"
      rx="6.00486"
      fill="#F96443"
    />
    <path d="M101.102 497.888H64.4376v-17.187h36.6644v17.187z" fill="#F4DDD7" />
    <path
      d="M128.483 470h62.103l15.924 28.644h-93.951L128.483 470z"
      fill="#F96443"
    />
    <circle
      cx="157.244"
      cy="576.968"
      r="60.2588"
      fill="#F96443"
      stroke="#F4DDD7"
      strokeWidth="7.80631"
    />
    <circle cx="258.07" cy="529.579" r="6.87449" fill="#F4DDD7" />
    <circle cx="157.242" cy="576.967" r="43.5385" fill="#F4DDD7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.271 10.7526c1.167 1.064 1.252 2.873.188 4.0406L103.786 49.553c-1.195 1.3681-1.847 3.0941-1.847 4.9015V107.14c0 1.579-1.28 2.86-2.86 2.86-1.5797 0-2.8602-1.281-2.8602-2.86V54.4545c0-3.2188 1.1728-6.2909 3.2859-8.6954l.017-.0194L131.23 10.9404c1.064-1.16762 2.873-1.25169 4.041-.1878zM179.524 10.7526c1.167-1.06389 2.976-.97982 4.04.1878l31.709 34.7993.017.0193c2.113 2.4046 3.286 5.4767 3.286 8.6955V107.14c0 1.579-1.281 2.86-2.86 2.86-1.58 0-2.861-1.281-2.861-2.86V54.4545c0-1.8074-.652-3.5334-1.847-4.9015l-31.672-34.7598c-1.064-1.1676-.98-2.9766.188-4.0406z"
      fill="#F96443"
    />
    <path
      d="M54.8604-27.0623H38.7773c-2.2091 0-4 1.7909-4 4V.514593c0 2.209137 1.7909 3.999997 4 3.999997h16.0831c2.2091 0 4-1.79086 4-3.999999V-23.0623c0-2.2091-1.7909-4-4-4z"
      fill="#F96443"
    />
    <path
      d="M116.524 6.57432L58.3467-4.29452V-20.9982l58.1773-2.4598V6.57432zM171.384 19.4458h-28.602v8.2374h28.602v-8.2374z"
      fill="#F96443"
    />
    <path
      d="M162.174 27.6824h-10.183v8.2374h10.183v-8.2374zM259.295 4.49194h16.083c2.209 0 4-1.79086 4-3.999998V-23.0849c0-2.2091-1.791-4-4-4h-16.083c-2.209 0-4 1.7909-4 4V.491943c0 2.209137 1.791 3.999997 4 3.999997z"
      fill="#F96443"
    />
    <path
      d="M197.64 6.57432l58.234-10.86884V-20.9982L197.64-23.458V6.57432zM172.528 79.7407h-30.833c-3.604 0-6.465-2.9174-6.465-6.4641V42.3862c0-3.6039 2.918-6.4641 6.465-6.4641h30.833c3.604 0 6.464 2.9174 6.464 6.4641v30.8904c0 3.5467-2.918 6.4641-6.464 6.4641z"
      fill="#F96443"
    />
    <path
      d="M157.085 65.5527c4.107 0 7.437-3.3295 7.437-7.4366 0-4.1072-3.33-7.4367-7.437-7.4367s-7.437 3.3295-7.437 7.4367c0 4.1071 3.33 7.4366 7.437 7.4366z"
      fill="#F7DCD6"
    />
    <path
      d="M157.084 72.6773c8.042 0 14.561-6.519 14.561-14.5607 0-8.0416-6.519-14.5607-14.561-14.5607s-14.561 6.5191-14.561 14.5607c0 8.0417 6.519 14.5607 14.561 14.5607z"
      stroke="#F7DCD6"
      strokeWidth="2.72"
      strokeMiterlimit="10"
    />
    <path
      d="M193.639-31.8098h-73.116c-2.209 0-4 1.7908-4 4v43.2552c0 2.2091 1.791 4 4 4h73.116c2.209 0 4-1.7909 4-4v-43.2552c0-2.2092-1.791-4-4-4z"
      fill="#F96443"
    />
  </svg>
)
