import styled from "@emotion/styled"
import React from "react"

import colors from "../../../styles/colors"
import {
  MobileMediaQuery,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_HORIZONTAL,
} from "../../../styles/constants"
import { ContentPadding, NewCard } from "../../cardWrappers/newCard"
import Typography from "../../typography"
import FeedbackLoopsBotAgent from "../../illustrations/feedbackLoopsBotAgent"

const SectionTitle = styled(Typography)`
  color: ${colors.primary};
`

const Card = styled(NewCard)`
  ${MobileMediaQuery} {
    flex-direction: column-reverse;
  }
`
const CardTitle = styled(Card)`
  ${MobileMediaQuery} {
    margin-top: 3rem;
  }
`

const TextContent = styled.div`
  padding: 0 ${PADDING_HORIZONTAL}px;
  flex: 1;

  ${MobileMediaQuery} {
    padding: 0 ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

const LargeTitle = styled(ContentPadding)`
  color: ${colors.primary};
  margin-bottom: 2rem;
  max-width: 1100px;
`

const SectionOneImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: ${PADDING_HORIZONTAL}px;
  margin-top: -100px;
  ${MobileMediaQuery} {
    justify-content: center;
    padding: 0 ${MOBILE_PADDING_HORIZONTAL}px 3rem
      ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

const FeedbackLoopsBotAgentSVG = styled(FeedbackLoopsBotAgent)`
  width: 100%;
  ${MobileMediaQuery} {
    min-height: 400px;
  }
`

const SelfServiceLiveSupportInOne = () => (
  <>
    <CardTitle>
      <LargeTitle>
        <Typography variant="hero" as="h2">
          Self-service and live support in one
        </Typography>
      </LargeTitle>
    </CardTitle>
    <Card>
      <TextContent>
        <SectionTitle variant="bodyLarge" as="h3">
          A seamless customer experience
        </SectionTitle>
        <Typography variant="bodyLarge">
          Problem definition, self-service and live video support are now
          smoothly connected. Customers get one access point for all requests,
          and never have to repeat themselves.
        </Typography>
        <SectionTitle variant="bodyLarge" as="h3" style={{ marginTop: "2rem" }}>
          Smart feedback loops
        </SectionTitle>
        <Typography variant="bodyLarge">
          Machine learning works across the platform. When tickets are solved,
          either by humans or bot, new data is fed back into the system. It
          keeps content up to date, makes troubleshooting more accurate and
          improves canned responses.
        </Typography>
      </TextContent>
      <SectionOneImageWrapper>
        <FeedbackLoopsBotAgentSVG />
      </SectionOneImageWrapper>
    </Card>
  </>
)

export default SelfServiceLiveSupportInOne
