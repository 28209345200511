import styled from "@emotion/styled"
import React from "react"

import {
  CardHero,
  ContentPadding,
  NewCard,
} from "../components/cardWrappers/newCard"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography, { typographyStyles } from "../components/typography"
import colors from "../styles/colors"
import {
  DesktopMediaQuery,
  MobileMediaQuery,
  MOBILE_PADDING_VERTICAL,
  PADDING_HORIZONTAL,
} from "../styles/constants"
import { WhyMavenoidHeaderSvg } from "../components/illustrations/whyMavenoidHeaderSvg"
import { WhyMavenoidHeaderHorizontalSvg } from "../components/illustrations/WhyMavenoidHeaderHorizontalSvg"
import BornToSolve from "../components/cards/whyMavenoid/bornToSolve"
import SelfServiceLiveSupportInOne from "../components/cards/whyMavenoid/selfServiceLiveSupportInOne"
import UniqueProductInsights from "../components/cards/whyMavenoid/uniqueProductInsights"
import JanAckalin from "../components/cards/testimonials/JanAckalin"
import { ChooseJustTheToolYouNeed } from "../components/cards/whyMavenoid/chooseJustTheToolYouNeed"
import { CardSelfService } from "../components/cards/tools/cardSelfService"
import { CardLiveSupport } from "../components/cards/tools/cardLiveSupport"

const Content = styled(ContentPadding)`
  flex: 4;
  color: ${colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const HeroContainer = styled(NewCard)`
  ${MobileMediaQuery} {
    flex-direction: column;
    border-radius: 0px;
  }
`

const DesktopHeader = styled(WhyMavenoidHeaderSvg)`
  height: 100%;
  ${MobileMediaQuery} {
    display: none;
  }
`
const MobileHeader = styled(WhyMavenoidHeaderHorizontalSvg)`
  ${DesktopMediaQuery} {
    display: none;
  }
`

const Image = styled.div`
  flex: 2;
  padding-right: ${PADDING_HORIZONTAL}px;
  ${MobileMediaQuery} {
    justify-content: center;
    padding: ${MOBILE_PADDING_VERTICAL}px 0;
    margin-bottom: 2rem;
  }
`

const HeroWrapper = styled(CardHero)`
  ${MobileMediaQuery} {
    border-radius: 0px;
    min-height: initial;
    margin: 0 -4px;
  }
`

const HeroTitle = styled(Typography)`
  ${MobileMediaQuery} {
    ${typographyStyles.hero}
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
`

const Description = styled(Typography)`
  color: ${colors.darkgrey};
  max-width: 520px;
  font-weight: 600;

  ${MobileMediaQuery} {
    ${typographyStyles.bodyLarge}
  }
`

const WhyMavenoid = () => {
  return (
    <Layout newsletter={null}>
      <SEO title="Why Mavenoid?" />
      <HeroWrapper variant="light">
        <HeroContainer>
          <Content>
            <HeroTitle variant="h1" as="h1">
              The world's #1 product support platform
            </HeroTitle>
            <Description variant="h6">
              For technical support, generic solutions just don’t cut it.
              Mavenoid is purpose-built for supporting users of physical
              products, machines, devices and gadgets.
            </Description>
          </Content>
          <Image>
            <DesktopHeader />
            <MobileHeader />
          </Image>
        </HeroContainer>
      </HeroWrapper>

      <BornToSolve />
      <SelfServiceLiveSupportInOne />
      <UniqueProductInsights />

      <JanAckalin style={{ marginTop: "3rem" }} />

      <ChooseJustTheToolYouNeed />

      <CardSelfService />
      <CardLiveSupport />
    </Layout>
  )
}

export default WhyMavenoid
