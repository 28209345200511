import React from "react"
import styled from "@emotion/styled"
import colors, { foregroundColors } from "../../styles/colors"
import {
  MobileMediaQuery,
  MOBILE_PADDING_HORIZONTAL,
  MOBILE_PADDING_VERTICAL,
  PADDING_HORIZONTAL,
  PADDING_VERTICAL,
  BORDER_RADIUS_LARGE,
} from "../../styles/constants"

const Wrapper = styled.div`
  max-width: 1360px;
  min-height: ${p => (p.noMinHeight ? "initial" : "600px")};
  height: ${p => (p.isFixedHeight ? "600px" : "initial")};
  border-radius: ${BORDER_RADIUS_LARGE}px;
  background-color: ${p => colors[p.variant]};
  color: ${p => foregroundColors[p.variant]};
  margin: 0 auto;
  margin-bottom: 4rem;

  ${MobileMediaQuery} {
    height: initial;
  }
`

const CardWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  height: 100%;
`

export const ContentPadding = styled.div`
  padding: ${PADDING_VERTICAL}px ${PADDING_HORIZONTAL}px;

  ${MobileMediaQuery} {
    padding: ${MOBILE_PADDING_VERTICAL}px ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

export const CardHero = ({
  variant = "white",
  noMinHeight = false,
  isFixedHeight = true,
  children,
  ...props
}) => (
  <Wrapper
    variant={variant}
    noMinHeight={noMinHeight}
    isFixedHeight={isFixedHeight}
    {...props}
  >
    {children}
  </Wrapper>
)

export const NewCard = ({ children, ...props }) => (
  <CardWrapper {...props}>{children}</CardWrapper>
)

export const NewCardTextContent = ({ children }) => (
  <CardWrapper>{children}</CardWrapper>
)
