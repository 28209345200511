import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import colors from "../../../styles/colors"
import Card from "../../card"
import { Testimonial } from "../../testimonial"
import { AlfaLaval } from "../cardPartners"

const JanAckalin = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "whyMavenoid/jan_ackalin.png" }) {
        childImageSharp {
          fixed(height: 210) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Card variant="white" noMinHeight {...props}>
      <Testimonial
        name="Jan Ackalin"
        workTitle="Head of Automation"
        company="Alfa Laval"
        description={`“I predict that Mavenoid becomes our infrastructure for solving
            technical problems.”`}
        profilePictureData={data}
        logo={<AlfaLaval fill={colors.darkgrey} style={{ height: "40px" }} />}
      />
    </Card>
  )
}

export default JanAckalin
