// @ts-check
import React from "react"

import { CardFullWidth } from "../cardFullWidth"
import { trackEvent } from "../../../utils/trackEvent"

export const ChooseJustTheToolYouNeed = () => {
  return (
    <CardFullWidth
      title="Choose just the tools you need"
      description=" Not all companies need the same thing, and your needs may change over
          time. That’s why we’re building Mavenoid like a toolbox. Both
          self-service and live support work great as stand-alone tools. Start
          with one—it’s easy to expand as you need."
      trackTryNow={() => {
        trackEvent({
          category: "sign_up_redirect",
          label: "why mavenoid choose the tool you need try now",
          siteSection: "why_mavenoid_try_now_banner",
        })
      }}
    />
  )
}
