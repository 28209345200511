import styled from "@emotion/styled"
import React from "react"
import Img from "gatsby-image/withIEPolyfill"

import colors from "../../../styles/colors"
import {
  MobileMediaQuery,
  MOBILE_PADDING_HORIZONTAL,
  PADDING_HORIZONTAL,
} from "../../../styles/constants"
import { ContentPadding, NewCard } from "../../cardWrappers/newCard"
import Typography from "../../typography"
import { graphql, useStaticQuery } from "gatsby"

const SectionTitle = styled(Typography)`
  color: ${colors.primary};
`

const Card = styled(NewCard)`
  ${MobileMediaQuery} {
    flex-direction: column-reverse;
  }
`

const TextContent = styled.div`
  padding: 0 ${PADDING_HORIZONTAL}px;
  flex: 1;

  ${MobileMediaQuery} {
    padding: 0 ${MOBILE_PADDING_HORIZONTAL}px;
  }
`

const LargeTitle = styled(ContentPadding)`
  color: ${colors.primary};
  margin-bottom: 2rem;
`

const SectionOneImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: ${PADDING_HORIZONTAL}px;
  ${MobileMediaQuery} {
    justify-content: center;
    padding: 0 4px;
    margin-bottom: 2rem;
  }
`

const GatsbyImage = styled(Img)`
  flex: 1;
`

const GatsbyImageWrapper = styled.div`
  width: 90%;
  ${MobileMediaQuery} {
    width: 100%;
  }
`

const BornToSolve = () => {
  const data = useStaticQuery(graphql`
    query {
      bornToSolve: file(relativePath: { eq: "whyMavenoid/born_to_solve.png" }) {
        childImageSharp {
          fluid(
            maxWidth: 620
            quality: 80
            traceSVG: {
              color: "#F96443"
              background: "#fff"
              blackOnWhite: true
            }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <>
      <Card>
        <LargeTitle style={{ maxWidth: "900px" }}>
          <Typography variant="hero" as="h2">
            Born to solve real problems
          </Typography>
        </LargeTitle>
      </Card>
      <Card>
        <TextContent style={{ maxWidth: "650px" }}>
          <SectionTitle variant="bodyLarge" as="h3">
            Solve tough problems that chatbots and help centers can’t handle
          </SectionTitle>
          <Typography variant="bodyLarge">
            Self-service no longer has to stop at simple tasks like answering
            FAQs or linking to articles. Mavenoid lets you automate what
            previously only humans could handle, like troubleshooting and
            warranties.
          </Typography>
          <SectionTitle
            variant="bodyLarge"
            as="h3"
            style={{ marginTop: "2rem" }}
          >
            Give every request the right level of service
          </SectionTitle>
          <Typography variant="bodyLarge">
            Customers expect both speed and personalized service at the same
            time. Mavenoid helps you find balance between self-service and human
            support by screening incoming requests and making sure they’re met
            with the most efficient mode of service.
          </Typography>
        </TextContent>
        <SectionOneImageWrapper>
          <GatsbyImageWrapper>
            <GatsbyImage
              fluid={data.bornToSolve.childImageSharp.fluid}
              alt="image"
            />
          </GatsbyImageWrapper>
        </SectionOneImageWrapper>
      </Card>
    </>
  )
}

export default BornToSolve
